<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col md="4">
            <v-row align="center" justify="center" class="flex-nowrap"
              ><v-col cols="auto">
                <v-img
                  contain
                  max-width="9em"
                  lazy-src="../assets/evacmanager_logo.png"
                  src="../assets/evacmanager_logo.png"
                ></v-img></v-col
              ><v-col cols="auto">
                <v-card color="#121212"
                  ><v-card-title class="text-lg-h4 text-sm-h5"
                    ><strong>Evac</strong>Manager</v-card-title
                  >
                  <v-card-subtitle
                    ><a
                      style="text-decoration: none"
                      href="https://wkn-datentechnik.de/"
                      target="blank"
                      >WKN Datentechnik GmbH</a
                    ></v-card-subtitle
                  ></v-card
                ></v-col
              ></v-row
            ><br />
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Anmeldung</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-alert
                  v-if="error"
                  :value="error"
                  color="red"
                  dark
                  border="top"
                  icon="mdi-cloud-alert"
                  transition="scale-transition"
                  >{{ errorMessage }}</v-alert
                >
                <!-- eslint-disable-next-line vue/no-deprecated-v-on-native-modifier -->
                <v-form @submit.prevent="login">
                  <v-text-field
                    v-model="username"
                    autocomplete="username"
                    label="Benutzername"
                    name="username"
                    prepend-icon="mdi-account"
                    type="text"
                    required
                    autofocus
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    v-model="password"
                    autocomplete="current-password"
                    label="Passwort"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    required
                    @keyup.enter="login"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit" @click="login"
                  >Anmelden</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      error: false,
      errorMessage: "",
      title: process.env.VUE_APP_TITLE,
    };
  },
  computed: {
    ...mapGetters("auth", {
      currentUser: "currentUser",
      currentToken: "currentToken",
    }),
  },
  created() {
    this.routeByRole();
  },
  updated() {
    this.routeByRole();
  },
  methods: {
    login: function () {
      const { username, password } = this;
      this.$store
        .dispatch("auth/AUTH_REQUEST", { username, password })
        .then(() => {
          this.routeByRole();
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.status + " " + error.data.message;
        });
    },
    routeByRole() {
      if (this.currentUser) {
        switch (this.currentUser.role) {
          case "Globaler Administrator":
            this.$router.replace(
              this.$route.query.redirect || "/global-administration"
            );
            break;
          default:
            this.$router.replace(
              this.$route.query.redirect || "/evacuation/live"
            );
            break;
        }
      }
    },
    checkCurrentLogin() {
      if (
        this.currentUser &&
        this.currentUser.role === "Globaler Administrator"
      ) {
        this.$router.replace(
          this.$route.query.redirect || "/global-administration"
        );
      }
      if (
        this.currentUser &&
        this.currentUser.role !== "Globaler Administrator"
      ) {
        this.$router.replace(this.$route.query.redirect || "/evacuation/live");
      }
    },
    loginSuccessful(req) {
      if (!req.data.token) {
        this.loginFailed();
      }
      localStorage.token = req.data.token;
      this.$store.dispatch("auth/login");
      this.error = false;
      if (this.currentUser.role === "Globaler Administrator") {
        this.$router.replace(
          this.$route.query.redirect || "/global-administration"
        );
      } else if (this.currentUser) {
        this.$router.replace(this.$route.query.redirect || "/evacuation/live");
      }
    },
    loginFailed(resp) {
      this.errorMessage =
        resp.data.message + " (" + resp.status + " " + resp.statusText + ")";
      this.error = true;
      this.$store.dispatch("auth/logout");
      delete localStorage.token;
    },
  },
};
</script>

<style lang="css"></style>
